import React from "react"
import legos from "legos"

/**
 * Renders an array of LEGOs
 * @param {[Object]} data LEGO parameters from Sanity GraphQL query
 * @param {Object} options Options
 * @param {Object} options.extraProps Additional props to pass to all LEGOs when rendering
 * @param {number} options.indexOffset If this isn't the first content on the page, offset the start of indexing
 * @param {Object} options.serializers Extra serializers for custom non-lego types
 */
export default (
  data,
  { extraProps = {}, indexOffset = 0, serializers = {} } = {}
) =>
  (data || []).filter(Boolean).map(({ _type = "", slug, ...props }, index) => {
    const type = _type.slice(0, 1).toUpperCase() + _type.slice(1)
    const serializer = serializers[type] || legos[type]

    if (serializer) {
      return React.createElement(serializer, {
        key: props._key,
        slug: slug?.current,
        index: index + indexOffset,
        ...extraProps,
        ...removePrefixes(props),
      })
    } else {
      if (process.env.NODE_ENV !== "production")
        console.warn(`No connector found for ${_type}`)
      return null
    }
  })

/**
 * Sometimes we need to fetch a field with an alias to avoid conflicts
 * between types on the LEGO union. To do this, we use prefix one of the
 * field names with an underscore (or multiple if needed), but this
 * creates cognitive overhead in the component definitions. This routine
 * strips these underscore-prefixes so the data is easier to work with.
 *
 * Note: `_key` is preserved to avoid conflicts with React internals.
 *
 * @param {*} props
 */
const removePrefixes = props =>
  Object.fromEntries(
    Object.entries(props).map(([name, value]) => [
      name === "_key" ? name : name.replace(/^_+/, ""),
      value,
    ])
  )
