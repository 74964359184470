import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import BlogPostGrid from "components/BlogPostsGrid"
import CTA from "components/CTA"
import theme from "theme"

const RecentPostsLego = ({ title, cta }) => {
  const {
    postData: { posts },
  } = useStaticQuery(
    graphql`
      {
        postData: allSanityBlogPost(limit: 3, sort: { fields: date, order: DESC }) {
          posts: nodes {
            date
            title
            slug {
              current
            }
            cardImage {
              ...ImageWithPreview
            }
          }
        }
      }
    `
  )

  return (
    <div
      css={{
        background: theme.gray1,
        padding: "60px 0",
        overflow: "hidden", // fixes iOS Safari overflow bug caused by BlogPostGrid
        position: "relative",
        zIndex: 2,
      }}
    >
      <h2
        css={{
          ...theme.h2,
          textAlign: "center",
          padding: "0 20px",
          marginBottom: 45,
        }}
      >
        {title}
      </h2>

      <BlogPostGrid posts={posts} cta={cta && <CTA {...cta} />} singleRow />
    </div>
  )
}

export default RecentPostsLego

RecentPostsLego.propTypes = {
  title: PropTypes.string.isRequired,
  cta: PropTypes.shape(CTA.propTypes),
}

export const query = graphql`
  fragment RecentPostsLego on SanityRecentPostsLego {
    title
    cta {
      ...CTA
    }
  }
`
