import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import { jsx } from "@emotion/react"
import PortableText from "react-portable-text"
import imageProptypes from "lib/imageProptypes"
import CTA from "components/CTA"
import Parallax from "components/Parallax"
import theme from "theme"

const BifoldLego = ({ content, reverse }) => (
  <div
    css={{
      display: "flex",
      flexDirection: reverse && "row-reverse",
      background: "inherit",
      position: "relative",
      zIndex: 2,
      [theme.mobile]: {
        display: "block",
      },
    }}
  >
    {content.map(({ _key, _type, ...props }) =>
      jsx(blockTypes[_type], { key: _key, ...props })
    )}
  </div>
)

export default BifoldLego

BifoldLego.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      _type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  reverse: PropTypes.bool,
}

const ImageBlock = ({ image, cta }) => (
  <div
    css={{
      flex: "0 0 50%",
      position: "relative",
      ":before": {
        content: "''",
        display: "block",
        paddingTop: "100%",
        [theme.mobile]: {
          display: "none",
        },
      },
    }}
  >
    <SanityImage
      {...image}
      width={720}
      sizes="(max-width: 900px) 50vw, 100vw"
      alt=""
      css={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.mobile]: {
          display: "block",
          position: "static",
          height: "auto",
        },
      }}
    />

    {cta && (
      <CTA
        {...cta}
        css={{
          position: "absolute",
          bottom: 60,
          left: "calc(50% - 30px)",
          transform: "translateX(-50%)",
          background: theme.blueGreen,
          width: "max-content",
          margin: "0 30px",
          maxWidth: "calc(100% - 60px)",
          [theme.mobile]: {
            display: "block",
            position: "static",
            transform: "none",
            textAlign: "center",
            padding: 30,
            margin: 0,
            width: "100%",
            maxWidth: "100%",
          },
          [theme.max(400)]: {
            textAlign: "left",
          },
        }}
      />
    )}
  </div>
)

ImageBlock.propTypes = {
  image: imageProptypes.isRequired,
  cta: PropTypes.shape(CTA.propTypes),
}

const TextBlock = ({ title, copy, cta }) => (
  <div
    css={{
      position: "relative",
      flex: "0 0 50%",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "0 auto",
      padding: "60px 10%",
      background: theme.white,
      [theme.mobile]: {
        padding: "30px 30px 60px",
        position: "static",
        height: "auto",
      },
    }}
  >
    <div
      css={{
        margin: "0 auto",
        maxWidth: 400,
        [theme.mobile]: {
          maxWidth: 315,
        },
      }}
    >
      {title && (
        <h2
          css={{
            ...theme.h2,
            [theme.mobile]: {
              paddingBottom: 10,
            },
          }}
        >
          {title}
        </h2>
      )}

      {copy && <PortableText content={copy} />}

      {cta && (
        <div
          css={{
            margin: "60px auto 0",
            textAlign: "center",
            [theme.mobile]: { margin: "42px auto 0", paddingBottom: 15 },
          }}
        >
          <CTA {...cta} />
        </div>
      )}
    </div>
  </div>
)

TextBlock.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.arrayOf(PropTypes.object),
  cta: PropTypes.shape(CTA.propTypes),
}

const offset = 30
const multiplier = 5

const ParallaxBlock = ({ title, images }) => (
  <div
    css={{
      flex: "0 0 50%",
      position: "relative",
      overflow: "hidden",
      ":before": {
        content: "''",
        display: "block",
        paddingTop: "100%",
      },
    }}
  >
    <h3
      css={{
        ...theme.h2,
        textAlign: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: "30px max(30px, calc((50vw - 390px) / 2))",
        zIndex: 3,
        color: theme.tan,
        display: "flex",
        alignItems: "center",
        [theme.mobile]: {
          padding: "30px max(30px, calc((100vw - 390px) / 2))",
        },
      }}
    >
      {title}
    </h3>

    <Parallax
      css={{
        position: "absolute",
        top: -1,
        left: 0,
        width: "100%",
        height: "100%",
      }}
      offset={offset}
    >
      {[...images].reverse().map((image, index) => (
        <SanityImage
          {...image}
          key={`${index}-${image.asset._id}`}
          width={720}
          height={720 + Math.abs(index - 1) * offset * multiplier}
          sizes="(max-width: 900px) 50vw, 100vw"
          alt=""
          css={{
            position: "absolute",
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
            width: "100%",
            height: "auto",
            minHeight: `calc(100% + ${
              (Math.abs(index - 1) * offset * multiplier) / 7.2
            }%)`,
          }}
          options={{
            __experimentalAspectRatio: true,
          }}
          perspective={multiplier * (index - 1)}
          wrapperProps={{
            css: {
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
            },
          }}
        />
      ))}
    </Parallax>
  </div>
)

ParallaxBlock.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(imageProptypes).isRequired,
}

const blockTypes = {
  bifoldImageBlock: ImageBlock,
  bifoldTextBlock: TextBlock,
  bifoldParallaxBlock: ParallaxBlock,
}

export const query = graphql`
  fragment BifoldLego on SanityBifoldLego {
    content {
      __typename

      ... on SanityBifoldTextBlock {
        _key
        _type
        title
        copy: _rawCopy
        cta {
          ...CTA
        }
      }

      ... on SanityBifoldImageBlock {
        _key
        _type
        image {
          ...ImageWithPreview
        }
        cta {
          ...CTA
        }
      }

      ... on SanityBifoldParallaxBlock {
        _key
        _type
        title
        images {
          ...ImageWithPreview
        }
      }
    }

    reverse
  }
`
