import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Button from "components/Button"

const CTA = ({ text, url, ...rest }) => (
  <Button href={url} {...rest}>
    {text}
  </Button>
)

export default CTA

CTA.propTypes = {
  text: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
}

export const query = graphql`
  fragment CTA on SanityCta {
    text
    url
  }
`
