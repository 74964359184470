import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import PortableText from "react-portable-text"
import imageProptypes from "lib/imageProptypes"
import theme from "theme"

const TextLego = ({ title, copy, stamp }) => (
  <div css={{ background: theme.white, position: "relative", zIndex: 2 }}>
    <div
      css={{
        margin: "0 auto",
        maxWidth: 425,
        padding: "175px 0 125px",
        textAlign: "center",
        [theme.mobile]: {
          padding: "115px 30px 80px",
        },
      }}
    >
      {title && <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>}

      {copy && (
        <PortableText
          content={copy}
          css={{
            ...theme.t1,
            marginTop: 50,
          }}
        />
      )}

      {stamp && (
        <SanityImage
          {...stamp}
          width={100}
          alt=""
          css={{
            maxWidth: 100,
            margin: "72px auto 0",
            [theme.mobile]: {
              maxWidth: 80,
            },
          }}
        />
      )}
    </div>
  </div>
)

export default TextLego

TextLego.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.arrayOf(PropTypes.object),
  stamp: imageProptypes.isRequired,
}

export const query = graphql`
  fragment TextLego on SanityTextLego {
    title
    _copy: _rawCopy
    stamp {
      ...ImageWithPreview
    }
  }
`
