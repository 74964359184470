// This file is automatically generated. Do not modify manually.
// To regenerate, run `yarn generate:index`.
import { graphql } from "gatsby"
import BifoldLego from "./BifoldLego"
import CtaLego from "./CtaLego"
import HeroLego from "./HeroLego"
import LegalTextLego from "./LegalTextLego"
import ProductsLego from "./ProductsLego"
import RecentPostsLego from "./RecentPostsLego"
import TextLego from "./TextLego"

export default {
  BifoldLego,
  CtaLego,
  HeroLego,
  LegalTextLego,
  ProductsLego,
  RecentPostsLego,
  TextLego,
}

export const query = graphql`
  fragment Legos on LegoType {
    # Shared fields
    _type
    _key

    # LEGO fragments
    ...BifoldLego
    ...CtaLego
    ...HeroLego
    ...LegalTextLego
    ...ProductsLego
    ...RecentPostsLego
    ...TextLego
  }
`
