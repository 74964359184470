import React from "react"
import PropTypes from "prop-types"
import SanityImage from "gatsby-plugin-sanity-image"
import imageProptypes from "lib/imageProptypes"
import CTA from "components/CTA"
import theme from "theme"
import { graphql, Link } from "gatsby"

const ProductsLego = React.forwardRef(
  ({ title, copy, ctas, productType, products }, ref) => (
    <div
      ref={ref}
      css={{
        background: theme.gray1,
        scrollMarginTop: 120,
        position: "relative",
        zIndex: 2,
      }}
      id={productType.slug.current}
    >
      <div
        css={{
          maxWidth: 790,
          margin: "0 auto",
          padding: "120px 30px 90px",
          textAlign: "center",
          [theme.mobile]: {
            padding: "54px 30px 78px",
          },
        }}
      >
        <h2 css={{ ...theme.h2, marginBottom: 25 }}>{title}</h2>

        {copy && <div css={{ maxWidth: 400, margin: "0 auto" }}>{copy}</div>}

        {ctas && ctas.length > 0 && (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              width: "max-content",
              margin: "44px auto 0",
            }}
          >
            {ctas.map((cta, index) => (
              <CTA
                key={index}
                {...cta}
                css={{
                  background: index >= 1 && theme.greyBrown,
                  marginBottom: 15,
                  ":last-child": {
                    marginBottom: 0,
                  },
                }}
              />
            ))}
          </div>
        )}
      </div>

      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "stretch",
          gap: 30,
          maxWidth: 1260,
          margin: "0 auto",
          padding: "0 30px",
          [theme.max(850)]: {
            gridTemplateColumns: "1fr",
            maxWidth: 585,
            padding: 0,
          },
          [theme.max(645)]: {
            gap: "max(0px, calc((100vw - 585px) / 2))",
          },
        }}
      >
        {products.map(({ _key, ...product }) => (
          <ProductCard key={_key} {...product} productType={productType} />
        ))}
      </div>
    </div>
  )
)

ProductsLego.displayName = "ProductsLego"

export default ProductsLego

ProductsLego.propTypes = {
  _key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  copy: PropTypes.node,
  ctas: PropTypes.arrayOf(PropTypes.shape(CTA.propTypes).isRequired),
  productType: PropTypes.shape({
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      stamps: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          image: imageProptypes.isRequired,
        }).isRequired
      ).isRequired,
      image: imageProptypes.isRequired,
    })
  ).isRequired,
}

const ProductCard = ({
  name,
  size,
  description,
  stamps,
  image,
  productType,
}) => (
  <div
    css={{
      background: theme.white,
    }}
  >
    <div css={{ position: "relative" }}>
      <SanityImage
        {...image}
        width={585}
        height={380}
        alt=""
        sizes="min(100vw, 585px)"
        css={{
          width: "100%",
          height: "auto",
        }}
        options={{
          __experimentalAspectRatio: true,
        }}
      />
      {stamps && (
        <div
          css={{
            position: "absolute",
            top: "100%",
            left: 30,
            right: 30,
            transform: "translateY(-50%)",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {stamps.map(stamp => (
            <SanityImage
              key={stamp._id}
              {...stamp.image}
              width={100}
              alt={stamp.name}
              css={{
                marginLeft: 12,
                width: 100,
                height: 100,
                objectFit: "contain",
                [theme.max(470)]: {
                  width: 55,
                  height: 55,
                },
              }}
              sizes="(max-width: 470px) 55px, 100px"
            />
          ))}
        </div>
      )}
    </div>

    <div
      css={{
        padding: "70px 30px",
        [theme.max(470)]: {
          padding: "40px 30px 60px",
        },
      }}
    >
      <h3 css={theme.h3}>{name}</h3>
      <div css={{ ...theme.detail, marginTop: 13 }}>{size}</div>
      <p css={{ margin: "24px 0" }}>{description}</p>
      <Link
        to={
          productType
            ? `/store-locator/#${productType.slug.current}`
            : "/store-locator/"
        }
        css={{ ...theme.h5, textDecoration: "underline" }}
      >
        Find a Store
      </Link>
    </div>
  </div>
)

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  stamps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: imageProptypes.isRequired,
    }).isRequired
  ).isRequired,
  image: imageProptypes.isRequired,
  productType: PropTypes.shape({
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  fragment ProductsLego on SanityProductsLego {
    title
    copy
    ctas {
      ...CTA
    }
    productType {
      name
      slug {
        current
      }
      icon {
        ...Image
      }
    }
    products {
      _key: _id
      name
      size
      description
      stamps {
        _id
        name
        image {
          ...Image
        }
      }
      image {
        ...ImageWithPreview
      }
    }
  }
`
