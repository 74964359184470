import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import imageProptypes from "lib/imageProptypes"
import AutoLink from "components/AutoLink"
import theme from "theme"

const CtaLego = ({ cards }) => (
  <div
    css={{
      padding: "90px 0",
      background: theme.gray1,
      position: "relative",
      zIndex: 2,
      [theme.mobile]: {
        padding: 0,
      },
    }}
  >
    <div
      css={{
        maxWidth: 792,
        margin: "0 auto",
      }}
    >
      {cards.map(({ _key, text, url, image }) => (
        <AutoLink
          key={_key}
          href={url}
          aria-label={text}
          css={{
            display: "flex",
            alignItems: "stretch",
            width: "100%",
            "& + &": { marginTop: 10, [theme.mobile]: { marginTop: 2 } },
          }}
        >
          <SanityImage
            {...image}
            width={175}
            height={175}
            alt=""
            css={{
              flex: "0 0 175px",
              display: "block",
              objectFit: "cover",
              height: "auto",
              [theme.max(500)]: {
                width: 150,
                flexBasis: 150,
              },
              [theme.smol]: {
                width: 100,
                flexBasis: 100,
              },
            }}
            options={{
              __experimentalAspectRatio: true,
            }}
          />
          <div
            css={{
              ...theme.h3,
              flex: "1 1 auto",
              display: "flex",
              alignItems: "center",
              background: theme.blueGreen,
              color: theme.tan,
              padding: "30px 30px 30px 75px",
              [theme.mobile]: {
                padding: 20,
                paddingLeft: "9.5%",
              },
              [theme.max(500)]: {
                paddingLeft: 20,
              },
            }}
          >
            {text} {text.endsWith("→") ? "" : "→"}
          </div>
        </AutoLink>
      ))}
    </div>
  </div>
)

export default CtaLego

CtaLego.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      image: imageProptypes.isRequired,
    }).isRequired
  ).isRequired,
}

export const query = graphql`
  fragment CtaLego on SanityCtaLego {
    cards {
      _key
      text
      url
      image {
        ...ImageWithPreview
      }
    }
  }
`
