import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ArticleText from "components/ArticleText"
import theme from "theme"

const LegalTextLego = ({ title, content }) => (
  <div
    css={{
      "--gutters": "30px",
      background: theme.white,
      padding: "100px var(--gutters)",
      position: "relative",
      zIndex: 2,
      [theme.mobile]: {
        padding: "50px var(--gutters)",
      },
    }}
  >
    {title && (
      <h1 css={{ ...theme.h1, textAlign: "center", marginBottom: 40 }}>
        {title}
      </h1>
    )}

    <ArticleText
      content={content}
      css={{ lineHeight: 1.5 }}
      childStyles={{ maxWidth: 600 }}
      serializers={{
        h2: props => (
          <h2
            css={{
              ...theme.h2,
              marginTop: 45,
              [theme.mobile]: { marginTop: 30 },
            }}
            {...props}
          />
        ),
      }}
    />
  </div>
)

export default LegalTextLego

LegalTextLego.propTypes = {
  title: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.object),
}

export const query = graphql`
  fragment LegalTextLego on SanityLegalTextLego {
    title
    _content: _rawContent
  }
`
