import React, { useState, useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { motion, useViewportScroll, useTransform } from "framer-motion"

const Parallax = ({ children, offset = 50, ...props }) => {
  const { scrollY } = useViewportScroll()
  const [inputRange, setInputRange] = useState([0, 100])
  const yRange = useTransform(scrollY, inputRange, [offset, -offset])
  const ref = useRef()

  useLayoutEffect(() => {
    const element = ref.current

    const onResize = () => {
      const elementDims = element.getBoundingClientRect()
      const elementTop = elementDims.top + window.scrollY
      const clientHeight = window.innerHeight

      setInputRange([
        elementTop + elementDims.height / 2 - clientHeight,
        elementTop + elementDims.height + offset,
      ])
    }

    onResize()

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [ref, offset])

  return (
    <div ref={ref} {...props}>
      {React.Children.map(
        children,
        (
          { props: { perspective, wrapperProps = {}, ...props }, ...child },
          index
        ) => (
          <ParallaxChild
            key={index}
            perspective={perspective}
            y={yRange}
            {...wrapperProps}
          >
            {React.cloneElement(child, props)}
          </ParallaxChild>
        )
      )}
    </div>
  )
}

export default Parallax

Parallax.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number,
  className: PropTypes.string,
}

const ParallaxChild = ({ children, perspective, y, className }) => {
  const yValue = useTransform(y, value => `${(perspective * value) / 2 / 7.2}%`)
  const ref = useRef()

  return (
    <motion.div ref={ref} style={{ y: yValue }} className={className}>
      {children}
    </motion.div>
  )
}

ParallaxChild.propTypes = {
  children: PropTypes.node.isRequired,
  perspective: PropTypes.number,
  y: PropTypes.object.isRequired,
  className: PropTypes.string,
}
