import React, { useContext } from "react"
import { motion } from "framer-motion"
import LayoutContext from "context/LayoutContext"
import theme from "theme"

const StickyNavBar = (props, ref) => {
  const { stickyHeader, headerDims } = useContext(LayoutContext)

  return (
    <motion.div
      ref={ref}
      initial={{
        top: 0,
      }}
      animate={{
        top: stickyHeader && headerDims?.height ? headerDims.height : 0,
      }}
      transition={{
        type: "spring",
        damping: 20,
      }}
      css={{
        background: theme.tan,
        position: "sticky",
        top: 0,
        zIndex: 3,

        // Prevent aliasing producing a gap between the header bar and the
        // filter bar during sticky animations.
        paddingTop: 2,
        marginBottom: -2,
        transform: "translateY(-2px)",
      }}
      {...props}
    />
  )
}

export default React.forwardRef(StickyNavBar)
